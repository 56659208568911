//Facturas
@font-face {
    font-family: 'Factura';
    src: url('C:/laragon/www/bsfacturacion/storage/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf');
}

#facturas-container{
    margin-top:2rem;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 63vh;
    height: fit-content;
    .card{
        width: 14rem !important;
        margin-bottom: 1rem;
        margin-right: 2rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        border:solid thin $primario;//#1099ad;
        background-color: #ffffff; //#E9F9E6;
        .card-img-top{
            height: 200px;
        }
        .card-title{
            margin-top: 0.5rem;
            height: 25px;
        }
    }
    .card:hover{
        cursor: pointer;
        transform: scale(1.05);
        transition: 0.5s;
    }


}



.logo-listado-recibo{
    width:20%;
    position: absolute;
    right: 0;
    top:0;
    height: 45px;
    border-radius: 20px;
}

//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

//PDF FACTURA
