//Cards
#clientes-container{
    margin-top: 2rem;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 63vh;
    height: fit-content;
    .card{
        width: 240px;
        height: 320px;
        margin-top: 100px;
        margin-bottom: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        border:solid thin $primario;//#1099ad;
        background-color: #ffffff; //#E9F9E6;
        .card-img-top{
            height: 200px;
        }
        .card-title{
            height: 50px;
        }
    }
    .card:hover{
        cursor: pointer;
        transform: scale(1.05);
        transition: 0.5s;
    }
}


.grey-filter{
    filter: grayscale(1);
}


//Tags
.tag-rojo{
    background-color: red;
    color: #fff;
    width: fit-content;
    padding: 0.2rem;
    text-align: right;
    margin-right: 0.4rem;
    font-size: smaller;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tag-verde{
    background-color: green;
    color: #fff;
    width: fit-content;
    padding: 0.2rem;
    margin-right: 0.4rem;
    font-size: smaller;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tag-info{
    background-color: lightseagreen;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: small;
    margin-bottom:0.4rem;
}


.tag-actividad{
    background-color: #6120B2;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: small;
    margin-bottom:0.4rem;
}


//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

//Pendientes aprobar
.pendientes-aprobar{
    background-color: #E9F9E6;
    border: solid thin #1099ad;
    border-radius: 5px;
}
.clientes-pendientes{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px 5px 5px 5px;
}
.socio-pendiente{
    background-color: lightseagreen;
    color: #ffffff;
    border-radius: 5px;
    margin:10px;
}

