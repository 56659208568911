.login {
    position : absolute;
    top : 200px;
    left: 100px;
    .card {
        width : 300px;
        .card-header {
            background-color : #f4f4f4;
            color : #000;
            font-weight : bold;
        }
        .card-body {
            padding : 20px;
            .form-group {
                margin-bottom : 20px;
            }
            .btn {
                width : 75%;
                margin :auto;
            }
        }
    }
}
.image-login{
    position : absolute;
    top : 0;
    right : 0;
    width : 100%;
    height : 100%;
    img {
        width : 100%;
        height : 100%;
    }
}

.logo-login{
    display: none;
}
