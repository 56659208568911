//Modal factura
@media screen and (max-width: 399px) {
    //Modal documento
    .logo-documento{
        display: flex;
        padding-left: 0px;
        padding-right: 0px;        
        img 
        {
            width: 30%;
        }
        h1{
            width: 70%;
            text-align: center;
            margin:auto;
            font-size: 35px;
        }
    }

    .cabecera-documento{
        .emisor-documento, .datos-emision-documento{
            width: 100%;
            padding-left: 0px;
            margin-bottom: 10px;
            div{
                width: 100%;
                h5{
                    width: 50%;
                }
                .fecha-documento{
                    width: 50%;
                }
                .num-documento{
                    width: 50%;
                }
                .cliente-id{
                    width: 50%;
                    text-align: end;
                }
            };
        }
    }
    .cliente-documento{
        padding-left: 30px;
    } 
    .items-documento{
        margin-left: 0px;
        margin-right: 0px;
        height: 200px;
        overflow-x: scroll;
        .cabecera-items{
            width: 891px;
            .cabecera-item-documento-cantidad, .cabecera-item-documento-cantidad p{
                width:60px;
                margin-right: 5px;
            }
            .cabecera-item-documento-nombre, .cabecera-item-documento-nombre p{
                width:500px;
                margin-right: 5px;
            }
            .cabecera-item-documento-precio, .cabecera-item-documento-precio p{
                width:80px;
                margin-right: 5px;
            }
            .cabecera-item-documento-descuento, .cabecera-item-documento-descuento p{
                width:91px;
                margin-right: 5px;
            }
            .cabecera-item-documento-importe, .cabecera-item-documento-importe p{
                width:80px;
                margin-right: 5px;
            }
            .cabecera-item-documento-borrar{
                display:flex;
                justify-content:space-around; 
                width:80px;
            }
        }
        .registro-items{
            width: 891px;
            .registro-item-documento-cantidad, .registro-item-documento-cantidad input{
                width:60px;
                margin-right: 5px;
            }
            .registro-item-documento-nombre, .registro-item-documento-nombre input{
                width:500px;
                margin-right: 5px;
            }
            .registro-item-documento-precio, .registro-item-documento-precio input{
                width:80px;
                margin-right: 5px;
            }
            .registro-item-documento-descuento, .registro-item-documento-descuento input{
                width:91px;
                margin-right: 5px;
            }
            .registro-item-documento-importe, .registro-item-documento-importe input{
                width:80px;
                margin-right: 5px;
            }
            .registro-item-documento-borrar{
                display:flex;
                justify-content:space-around; 
                width:80px;
            }
        }
    }
    .footer-items-documento{
        padding-right: 0px;
        padding-left:0px;
    }
}



