//Empresas
#formEmpresa {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border: solid thin $primario;
    border-radius: 10px;
    padding: 20px;

    .form-empresa-body{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;

        .form-empresa-body-logo{
            width:25%;
        }
        .form-empresa-body-datos{
            width:50%;
        }
    }
}

