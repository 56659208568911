//Modal cliente
@media screen and (max-width: 399px) {
    .modal-cliente-body{
        display:flex;
        justify-content:space-around;
        flex-direction: column;
    }    
    .modal-cliente-body-part1{
        border-right:none;
        width:100%;
    }
    
    .modal-cliente-body-part2{
        width:100%;
    }
    .modal-cliente-body-part2-inputgroup2,.modal-cliente-body-part2-inputgroup3{
        flex-direction: column;
    }
    .modal-cliente-body-part2-inputgroup2 .form-group,.modal-cliente-body-part2-inputgroup3 .form-group{
        width:100%;
    }


    #contenedor-avatar{
        margin-left:2.5rem;
    }
}