//Home
.home-container{

    min-height: 85vh;
}


.home-container .card{
    border-radius: 10px;
    margin-bottom: 1rem;
}

.home-container .card-header{
    background-color: $primario;//#E9F9E6;
    color: #ffffff;//lightseagreen;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.home-container .card-body{
    border:solid thin $primario;//#1099ad;
    background-color: #ffffff; //#E9F9E6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}

.home-container .card a{
    text-decoration: none;
    color: black;
}
.home-container .card a:hover{
    color: lightseagreen;
}

.card-mini{
    width: 30%;
}

.charts{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    margin-top:1rem; 
    justify-content:space-between;
}

.chart-container{
    width: 49%;
}

.carnet{
    width: 350px;
    height: 500px;
    border: solid thin lightseagreen;
    border-radius: 10px;
    background-image: url('/storage/images/fondo_negro.png');
    box-shadow: 10px 13px 20px 6px;
    margin: auto;
}
.carnet-avatar{
    width: 150px;
    margin: 10px;
    border-radius: 10px;
}
.carnet-qr{
    width: 150px;
    margin: auto;
}
.carnet-nombre{
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}
.carnet-numerosocio{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}
.carnet-estadoactivo{
    text-align: center;
    background-color: green;
    color: #fff;
}
.carnet-estadonoactivo{
    text-align: center;
    background-color: red;
    color: #fff;
}
.carnet-actividad{
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}
.carnet-logogym{
    width: 80px;
}

.trimestre-pasado{
    font-weight: bolder;
}