.registro-historial, .registro-lista, .registro-items, .cabecera-items, .cabecera-historial, .cabecera-lista, .registro-historial-items{
    border-bottom: solid thin #1099ad;
    background-color: #fff;
    padding: .3rem;

}

.registro-historial-items{
    display: none;
}

.registro-historial img{
    width: 100%;
}
.registro-historial label{
    font-size: smaller;
    text-align: center;
}

#servicios-lista, #historial-lista, #clientes-lista{
    overflow-y: scroll;
    height: 400px;
    border: solid thin #1099ad;
    border-radius: 10px;
}


//Overflow
#servicios-lista::-webkit-scrollbar{
    -webkit-appearance: none;
}
#servicios-lista::-webkit-scrollbar:vertical{
    width:10px;
}
#servicios-lista::-webkit-scrollbar-button:increment,#servicios-lista::-webkit-scrollbar-button{
    display: none;
} 
#servicios-lista::-webkit-scrollbar:horizontal{
    height: 10px;
}
#servicios-lista::-webkit-scrollbar-thumb{
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
#servicios-lista::-webkit-scrollbar-track{
    border-radius: 10px;  
}