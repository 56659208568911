@media screen and (max-width: 399px) {
    #servicios-container{
        height: fit-content;
        align-items: center;
        .card{
            width: 370px !important;
            margin-bottom: 3rem;
            .card-title{
                height: 80px;
                font-size: medium;
            }
        }
    }

    

    //Tags
    .tag-rojo, .tag-verde, .tag-info, .tag-actividad{
        font-size: medium;
    }
    
}



