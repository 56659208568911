// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Colores
$primario: #0e6cb1;
$tono-primario: #9F9F9F;
$secundario: #44d9e6;
