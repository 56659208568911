//Etiquetas
.etiquetas{
    border: #374CD1 solid thin;
    background-color:#FFF;
    color: black;
    border-radius: 10px;
    padding: .1rem .5rem;
}
.etiquetas i{
    margin: auto;
    padding-left: 1rem;
}
.etiquetas i:hover{
    cursor: pointer;
}

.actividad{
    margin-bottom:0;
}
