@media screen and (max-width: 399px) {
   //Empresas
    #formEmpresa {
        .form-empresa-body{
            display: block;
            .form-empresa-body-logo{
                width:100%;
            }
            .form-empresa-body-datos{
                width:100%;
            }
        }
    }


    
}



