//Tablas
.custom-table{
    background-color:lightseagreen;
    border-radius: 20px;
    cursor:pointer;
    width: 100%;
    color:#FFF;    
}
.custom-table tbody tr {
    border-top:solid thin #374CD1;
}
.custom-table tbody tr:hover {
    background: linear-gradient(-45deg, #9FE0FF,#B7F3D4,#9FE0FF);
    border-top:solid thin #374CD1;
}

.custom-table td, .custom-table th {
    padding: 1rem;
}