main{
    //background-color: $tono_primario;
    font-family: 'Roboto', sans-serif;
    background-image: url('/storage/images/background_login.png');
    background-size: cover;

}

//Barra navegacion
.nav-name-app{
    display: none;
}
.navbar,footer{
    background-color: $primario;//#E9F9E6;//#1099ad;
}

.logo-nav{
    border-radius: 50%;
}

.navbar-aux{
    background-color: transparent;//#f8fafc !important;
    margin: 0;
}

.nav-link{
    color: #ffffff;//lightseagreen;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
}

.nav-link:hover{
    background-color: #E5F0FE; //lightseagreen;
}

.navbar-brand img {
    height:65px;
    width:65px;
}

.container-fluid{
    padding-left: 0;
}

footer{
    padding: 1rem;
    color: #ffffff !important;
}

//Botones
.btn-success{
    background-color: $secundario !important;    //lightseagreen !important;
    color: #E8F8F5 !important;
}

.boton{
    background-color: $primario !important;    //lightseagreen;
    color: #ffffff;
}

.icono-boton{
    font-size: 1rem;
}

.boton:hover{
    background-color: #F5F4E5;
}

//Scrollbar style
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
margin-right: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}

//Errores
.cajaError{
    background-color: #483948;
    color: #ffffff;
    width: fit-content;
    border: solid thin #483948;
    border-radius: 0.5rem;
    padding: 0.3rem;
    display: flex;
    flex-wrap: wrap;
    margin: .7rem;
    font-size: smaller;
    align-items: center;
}

.hide-error{
    cursor: pointer;
}

.capitalise
{
    text-transform:capitalize;
}

.alert{
    margin-bottom: 0;
}

.lista-items-card{
    padding-left: 0; 
    text-align:left;
    height:110px; 
    width:190px; 
    overflow:overlay;

    li{
        list-style-position: inside;
    }
}

.tag-1trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #1BB10E;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
.tag-2trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #B1530E;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
.tag-3trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #A40EB1;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
.tag-4trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #0E6CB1;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}