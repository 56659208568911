@media screen and (max-width: 399px) {
    //Barra navegacion
    .nav-name-app{
        display: block;
        text-align: center;
        color: $primario;
        font-size: 20px;
        font-family: 'Nunito';
        border: solid thin #44d9e6;
        padding: 7px;
        border-radius: 30px;
        background-color: white;
        font-weight: bolder;
        box-shadow: #44d9e6 0px 0px 7px 5px;
    }
    .navbar-toggler-icon{
        color: white;
    }
    .navbar-img-avatar{
        position: absolute;
        top: -115px;
        right: 0;
    }

    #acciones{
        margin-bottom: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .icono-boton{
        font-size: 1.5rem;
    }
}