@media screen and (max-width: 399px) {
    //Cards
    #proveedores-container{
        .card{
            width: 370px !important;
            margin-bottom: 3rem;
            margin-right: 1rem;
            margin-left: 1rem;
            .card-img-top{
                height: fit-content;
            }
            .card-title{
                height: 4rem;
                font-size: 20px !important;
                text-align:center;
                width: 100%;
                margin: auto;
            }
        }

    } 

    //Tags
    .tag-rojo, .tag-verde, .tag-info, .tag-actividad{
        font-size: medium;
    }
    
}



