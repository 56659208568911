@media screen and (max-width: 399px) {
    .login {
        position : absolute;
        top : 70px;
        left: 20px;
        .card {
            width : 300px;
            .card-header {
                background-color : #f4f4f4;
                color : #000;
                font-weight : bold;
            }
            .card-body {
                padding : 20px;
                .form-group {
                    margin-bottom : 20px;
                }
                .btn {
                    width : 75%;
                    margin :auto;
                }
            }
        }
    }
    .image-login{
        position : absolute;
        top : 0;
        right : 0;
        width : 100%;
        height : 100%;
        img {
            width : 200%;
            height : 100%;
        }
    }

    .logo-login{
        display: block;
        position: absolute;
        top: 500px;
        left: 80px;
        width: 200px;
        z-index: 1;
        filter: brightness(1.1);
        mix-blend-mode: multiply;
    }
}

