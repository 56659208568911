//Servicios
#servicios-container{
    margin-top: 2rem;
    min-height: 63vh;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card{
        width: 14rem !important;
        height: 200px;
        margin-bottom: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        border:solid thin $primario;//#1099ad;
        background-color: #ffffff; //#E9F9E6;
        .card-title{
            height: 80px;
            font-size: smaller;
        }
    }
    .card:hover{
        cursor: pointer;
        transform: scale(1.05);
        transition: 0.5s;
    }
}


#servicios-container .card-img-top{
    height: 200px;
}


//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

