//Informes pdf
@font-face {
    font-family: 'Informe';
    src: url('https://bsgym.test/storage/fonts/Roboto-Regular.ttf');
}


.letra-informe-titulo {
    font-family:Informe;
    font-size: 24px;
}

.letra-informe-cabecera {
    font-family:Informe;
    font-size: 12px;
    color: #ffffff;
    background-color: lightseagreen;
}

.letra-informe-celda {
    font-family:Informe;
    font-size: 12px;
}

th,td {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: solid thin black;
}

tr:nth-child(odd){
    background-color: #E9F9E6;
}
