// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Layoute
@import 'layout';
@import 'layout-mobile';
@import 'choices';

//Home
@import 'home';
@import 'home-mobile';

//Clientes
@import 'clientes';
@import 'clientes-mobile';
@import 'modal-cliente';
@import 'modal-cliente-mobile';

//Proveedores
@import 'proveedores';
@import 'proveedores-mobile';
@import 'modal-proveedor';
@import 'modal-proveedor-mobile';

//Empresas
@import 'empresas';
@import 'empresas-mobile';


//Servicios
@import 'servicios';
@import 'servicios-mobile';

//Facturas
@import 'facturas';
@import 'facturas-mobile';

//Gastos
@import 'gastos';
@import 'gastos-mobile';

//Presupuestos
@import 'presupuestos';
@import 'presupuestos-mobile';

//Documento
@import 'modal-documento';
@import 'modal-documento-mobile';


//Historiales
@import 'historiales';

//Modales
@import 'modales';

//Tablas
@import 'tablas';

//Avisos
@import 'etiquetas';

//Informes
@import 'informes';

//Login
@import 'login';
@import 'login-mobile';





//Forms
.custom-form{
    background: linear-gradient(-45deg, #4cd137,#A6E89B,#4cd137);
    border-radius: 20px;
    border: solid thin #374CD1;
    color:#FFF;    
}

//Recibo pdf

.label-pdf-recibo{
    font-weight: bold;
    font-size: small;
}
.dato-pdf-recibo{
    font-size: smaller;
}

