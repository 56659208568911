//Modal cliente

.modal-cliente-body{
    display:flex;
    justify-content:space-around;
}

.modal-cliente-body-part1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right:solid thin black;
    width:25%;
    padding:0.8rem;
}

.modal-cliente-body-part2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:70%;
    padding:0.5rem;
}

.modal-cliente-body-part2-inputgroup2,.modal-cliente-body-part2-inputgroup3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-cliente-body-part2-inputgroup2 .form-group{
    width:48%;
}
.modal-cliente-body-part2-inputgroup3 .form-group{
    width:30%;
}