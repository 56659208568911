//Modales
.modal-content{
    //background: linear-gradient(-45deg, #4cd137,#A6E89B,#4cd137);
    border-radius: 20px;
    cursor:pointer;
    width: 100%;
    color:black;
    border:solid thin $primario;//#1099ad;
    background-color: #E5F0FE; //#E9F9E6;
}


.modal-body #recibo{
    background-color: #FFF;
    
    height: 300px;
}

#contenedor-avatar{
    width: 50%;
}

#contenedor-avatar img{
    height: 250px;
    width: 220px;
    border-radius: 0.375rem;
}

#contenedor-avatar input{
    width: 220px;
}

.borraImagen{
    display: block !important;
    text-align: center;
    position: absolute;
    z-index: 9999;
    top: 16px;
    left: 16px;
    background-color: silver;
    padding: 15px;
    font-size: 18px;
    color: red;
    border-radius: 0.375rem 0 25% 0;
}
